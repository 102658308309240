import { UseDatum } from "react-usedatum";
import {
  Lap,
  RegattaConfig,
  Results,
  QRCodeInfo,
  ResultRegattaInfo,
  TrackingStation,
} from "./CrewTimerTypes";
import { prepareResultsForDisplay } from "./ResultUtils";
import {
  N_TRACKING_STATIONS,
  N_INFO_TEXT,
  N_PENALTY_CODE,
  N_POINTS_ENGINE,
  N_TITLE,
  N_LOGO_URL,
} from "./Names";
import {
  getOfficialEntries,
  setOfficialEntries,
  setRegattaConfig,
  setTimeSystems,
} from "./RegattaState";
import {
  closeFirebaseDatum,
  watchFilteredFirebaseDatum,
  watchFirebaseDatum,
} from "./WatchFirebase";
import { UseKeyedDatum } from "./UseKeyedDatum";
import { Event } from "./Event";
import KeyMap from "./KeyMap";
import { getKiosk } from "./UseKiosk";

export interface EventSummary {
  crews: Set<string>;
  strokes: Set<string>;
  crewAbbrevs: Set<string>;
  EventNum: string;
  Event: string;
  Day: string;
  Start: string;
  NumEntries: number;
  Official: boolean;
  RaceType: string;
}

export const [useQRCodes, _setQRCodes] = UseDatum<KeyMap<QRCodeInfo>>({});
export const [useTrackingStations, _setTrackingStations] = UseDatum<
  TrackingStation[]
>([]);
export const [usePointsEngine, _setPointsEngine] = UseDatum("None");
export const [useRegattaTitle, _setRegattaTitle] = UseDatum("");
export const [useRegattaSubtitle, _setRegattaSubtitle] = UseDatum("");
export const [useLogoURL, _setLogoURL] = UseDatum("");
export const [useInfoText, _setInfoText] = UseDatum("");
export const [useResultsError, setResultsError] = UseDatum("");

export const [useLastUpdatedEvent, setLastUpdatedEvent, getLastUpdatedEvent] =
  UseDatum("");
export const [useEventSummaryList, setEventSummaryList] = UseDatum<
  EventSummary[]
>([]);
export const [useRegattaInfo, setRegattaInfo] = UseDatum<
  ResultRegattaInfo | undefined
>(undefined);
let eventCache: KeyMap<Event> = {};
export const [
  useEventResult,
  setEventResult,
  clearEventResults,
  getEventResult,
] = UseKeyedDatum<Event>((key: string) => {
  return eventCache[key] || { EventNum: "", entries: [] };
});

// const EmptyResults: Results = {
//   [N_LAST_UPDATED_EVENT]: '',
//   [N_REGATTA_INFO]: {} as unknown as RegattaInfo,
//   [N_RESULTS]: [],
//   [N_ENTRY_PROGRESS]: {} as unknown as EntryProgress,
// };
// export const [useRegattaResults, setRegattaResults] = UseDatum<Results>(EmptyResults);

export const [useEventResults, _setEventResults] = UseDatum<Event[]>([]);
const removeParensRegex = /\([^()]*\)/g;
const setEventResults = (events: Event[]) => {
  _setEventResults(events);
  const cache: KeyMap<Event> = {};
  events.forEach((event) => {
    // if (!deepCompare(`${event.EventNum}`, eventCache[event.EventNum], event, undefined, true)) {
    //   console.log('compare fail');
    // }
    cache[event.EventNum] = event;
  });
  eventCache = cache;
  events.forEach((event) => {
    setEventResult(event.EventNum, event);
  });

  // generate list of crew names
  const eventSummaries = events.map((row) => {
    const crews = new Set<string>();
    const strokes = new Set<string>();
    const crewAbbrevs = new Set<string>();
    if (!row.entries) {
      row.entries = [];
    }
    row.entries.forEach((entry) => {
      if (!entry) {
        return;
      }

      // strip trailing ' A', ' B' boat class
      const crew = entry.Crew.replace(/ [A-Za-z]$/, "");
      // split name fields by semicolon if present and add to set of names
      // Dont use / for crew due to french usage of 'CrewName (Name1 / Name2)' for crew
      crew
        .replace(removeParensRegex, "")
        .split(";")
        .forEach((c) => {
          c = c?.trim();
          if (c) {
            crews.add(c);
          }
        });

      let stroke = entry.Stroke || "";
      if (stroke) {
        if (stroke.includes(";")) {
          stroke = stroke.replace(removeParensRegex, "").trim();

          stroke.split(";").forEach((s) => {
            s = s?.trim();
            if (s) {
              strokes.add(s);
            }
          });
        } else if (stroke.includes("/")) {
          // For stroke only, use / as separator.
          stroke.split("/").forEach((s) => {
            s = s?.trim();
            if (s) {
              strokes.add(s);
            }
          });
        } else {
          strokes.add(stroke.trim());
        }
      }
      const crewAbbrev = entry.CrewAbbrev || "";
      if (crewAbbrev) {
        crewAbbrevs.add(crewAbbrev.trim());
      }
    });

    const entryCount = row.entries.reduce(
      (count, entry) =>
        entry.Crew === "-" || entry.Bow === "-" ? 0 : count + 1,
      0
    );
    return {
      crews,
      strokes,
      crewAbbrevs,
      EventNum: row.EventNum,
      Event: row.Event || `${row.EventNum} Unknown Event`,
      Start: row.Start || "",
      Day: row.Day || "",
      NumEntries: entryCount,
      Official: row.Official,
      RaceType: row.RaceType,
    };
  });
  setEventSummaryList(eventSummaries);
};

let isAdminSite: boolean = false;
export const setIsAdminSite = (isAdmin: boolean) => {
  isAdminSite = isAdmin;
};

let resultsRegatta = "";

export const watchRegattaData = (regattaId: string) => {
  // console.log(`Watch regatta '${regattaId}' resultsRegatta='${resultsRegatta}'`);
  if (regattaId === resultsRegatta) {
    // No Change
    return;
  }
  // console.log('Clearing regatta data UseDatums');
  setRegattaConfig(undefined);
  setEventResults([]);
  setLastUpdatedEvent("");
  setRegattaInfo(undefined);
  setEventSummaryList([]);
  const regattaToClose = resultsRegatta;
  resultsRegatta = regattaId;

  // Fire updates in separate thread since they affect other components
  setTimeout(() => {
    // If the regatta changes, close out the old one and prep for new one
    if (regattaToClose) {
      closeFirebaseDatum(regattaToClose, true);
      setResultsError("");
    }
    watchFirebaseDatum<Results>(`results/${regattaId}`, (data: any) => {
      const results = prepareResultsForDisplay(data, isAdminSite);
      if (regattaId === resultsRegatta) {
        _setRegattaTitle(results.regattaInfo[N_TITLE]);
        _setLogoURL(results.regattaInfo[N_LOGO_URL] || "");
        _setInfoText(results.regattaInfo[N_INFO_TEXT] || "");
        _setPointsEngine(results.regattaInfo[N_POINTS_ENGINE] || "None");
        _setTrackingStations(results.regattaInfo[N_TRACKING_STATIONS] || []);
        if (getKiosk() && results.lastUpdatedEvent) {
          setLastUpdatedEvent(results.lastUpdatedEvent);
        }
        setEventResults(results.results || []);
        setRegattaInfo(results.regattaInfo);
      }
      return results;
    });

    if (isAdminSite) {
      // console.log(`Setting up admin watch for regatta ${regattaId}`);
      // also make some settings available
      watchFirebaseDatum(`regatta/${regattaId}/settings/qrcodes`, (data) =>
        _setQRCodes(data ? { ...data } : {})
      );
      watchFirebaseDatum(`regatta/${regattaId}/settings/timeSystems`, (data) =>
        setTimeSystems(data ? { system: {}, events: {}, ...data } : {})
      );
      watchFirebaseDatum(
        `regatta/${regattaId}/settings/config`,
        (config: RegattaConfig) => {
          if (regattaId === resultsRegatta) {
            setRegattaConfig(config);
          }
        }
      );
      watchFilteredFirebaseDatum(
        `regatta/${regattaId}/lapdata`,
        N_PENALTY_CODE,
        "Official",
        (result: Lap) => {
          setOfficialEntries({
            ...getOfficialEntries(),
            [result.uuid]: result,
          });
        }
      );
    }
  }, 0);
};
