import React, { FC, useEffect, useState } from 'react';
import Util from './shared/Util';
import * as Names from './shared/Names';
import LoadingIndicator from './shared/LoadingIndicator';
import RegattaConfigurationForm from './forms/RegattaConfigurationForm';
import { useNavigate, useLocation } from 'react-router-dom';
import { RegattaConfig, TrackingStation } from './shared/CrewTimerTypes';
import { useToast } from './shared/Toast';
import { useSelectedRegatta } from './shared/RegattaState';
import { _setRegattaTitle } from './shared/UseResults';
import uuidgen from 'short-uuid';

const RegattaEdit: FC = () => {
  const [regatta, setRegatta] = useState('');
  const [config, setConfig] = useState<RegattaConfig>({} as RegattaConfig);
  const [testingUrl, setTestingUrl] = useState(false);
  const [testingStatus, setTestingStatus] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [, setToast] = useToast();
  const [, setSelectedRegatta] = useSelectedRegatta();
  if (!config[Names.N_FEE_TYPE]) {
    config[Names.N_FEE_TYPE] = 'Fee';
  }
  useEffect(() => {
    const fields = location.pathname.split('/');
    let regattaId = '';
    if (fields.length >= 3) {
      regattaId = fields[2];
      if (regattaId === 'new') {
        const data = Util.createEmptyRegatta(regattaId) as RegattaConfig;
        setTimeout(() => {
          setRegatta(regattaId);
          setSelectedRegatta(regattaId);
          setConfig(data);
        }, 16);
      } else {
        Util.getRegattaConfig(regattaId)
          .then((data) => {
            setRegatta(regattaId);
            setConfig(data);
          })
          .catch((reason) => {
            setToast('Error querying regatta ', reason);
          });
      }
    }
  }, [location.pathname, setToast, setSelectedRegatta]);

  const onCancel = () => {
    if (regatta === 'new') {
      navigate('/', { replace: true });
    } else {
      navigate(`/manage/${regatta}`, { replace: true });
    }
  };
  const onSubmitAsync = async (newConfig) => {
    // Only keep specified fields
    const data = {} as RegattaConfig;
    // for (let i = 0; i < Names.REGATTA_CONFIG_FIELDS.length; i++) {
    for (const key of Names.REGATTA_CONFIG_FIELDS) {
      if (newConfig[key] === undefined) continue;
      data[key] = newConfig[key];
    }

    const mobilePins = (newConfig[Names.N_MOBILE_PINS] || []).filter((cfg) => cfg.pin && cfg.stationType);
    data[Names.N_MOBILE_PINS] = mobilePins;
    const newRegatta = regatta === 'new';
    let regattaId = regatta;
    if (newRegatta) {
      // Update any fields that createEmptyRegatta didn't have enough info to set correctly
      regattaId = await Util.createRegattaId();
      data[Names.N_OWNER] = Util.getUser()?.email || '';
      data[Names.N_UID] = Util.getUser()?.uid;
      data[Names.N_NAME] = regattaId;
      data[Names.N_CLOUD_KEY] = `${regattaId}-${uuidgen.generate()}`;
    }
    if (!config.CloudKey && !data.CloudKey) {
      data.CloudKey = `${regattaId}-${uuidgen.generate()}`;
    }

    const csvdate = new Date().toISOString();
    data[Names.N_CSV_TIMESTAMP] = csvdate;

    // Look for changes that will auto-reload the data source
    // 1. Data Source change
    // 2. RMRSID for RegattaMaster
    // 3. DOC URL for Google Sheets
    const dataSourcePrior = config.DataSource || Names.N_DATA_SOURCE_GOOGLE;
    let dataReload = dataSourcePrior !== data[Names.N_DATA_SOURCE];
    if (data[Names.N_DATA_SOURCE] === Names.N_DATA_SOURCE_RM && config[Names.N_RMRSID] !== data[Names.N_RMRSID]) {
      dataReload = true;
    }
    if (data[Names.N_DATA_SOURCE] === Names.N_DATA_SOURCE_GOOGLE && config[Names.N_DOC_URL] !== data[Names.N_DOC_URL]) {
      dataReload = true;
    }

    // sanitize control point names
    if (data[Names.N_TRACKING_STATIONS]) {
      const stations: TrackingStation[] = [];
      data[Names.N_TRACKING_STATIONS].forEach((pt) => {
        const name = pt.name.trim();
        if (name) {
          pt.name = name;
          stations.push(pt);
        }
      });
      data[Names.N_TRACKING_STATIONS] = stations;
    }

    Util.setRegattaConfig(regattaId, data)
      .then(() => {
        if (newRegatta || dataReload) {
          Util.reloadCsv(regattaId)
            .then()
            .catch((e) => {
              setToast(`Reload Error: ${String(e)}`);
            });
        }
      })
      .catch((e) => {
        setToast(`Reload Error: ${String(e)}`);
      });

    _setRegattaTitle(data[Names.N_TITLE]);
    navigate(`/manage/${regattaId}`, { replace: true });
  };

  const onSubmit = (newConfig) => {
    onSubmitAsync(newConfig).catch((e) => setToast(`Create error: ${String(e)}`));
  };

  const onTestUrl = (testconfig) => {
    const regattaId = testconfig[Names.N_NAME];
    console.log('testing regatta ', regattaId, ' ', testconfig[Names.N_DOC_URL]);
    Util.reloadCsv(regattaId, testconfig[Names.N_DOC_URL])
      .then((result) => {
        const status = result.status;
        console.log('result: ', status);
        setTestingUrl(false);
        setTestingStatus(status);
        return result;
      })
      .catch((reason) => {
        console.log('fail: ', reason);
        setTestingUrl(false);
        setTestingStatus(reason);
      });
    setTestingUrl(true);
  };

  if (regatta) {
    return (
      <div style={{ width: '100%', maxWidth: 700, margin: 'auto' }}>
        <RegattaConfigurationForm
          onSubmit={onSubmit}
          onCancel={onCancel}
          onTestUrl={onTestUrl}
          testingUrl={testingUrl}
          testingStatus={testingStatus}
          properties={config}
        />
      </div>
    );
  } else {
    return <LoadingIndicator />;
  }
};

export default RegattaEdit;
