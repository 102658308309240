/**
 * Created by glenne on 6/10/2017.
 */
import React, { FC } from 'react';
import * as Names from './Names';
import { ElapsedClock } from './ElapsedClock';
import { useEventResult } from './UseResults';

interface EventTableProps {
  eventNum: string;
}

/**
 * Displays an elapsed clock for a given event, showing the time elapsed since the event's start.
 *
 * This component fetches event data using the `useEventResult` hook and determines
 * if the event is in progress. If the event is in progress, it renders an `ElapsedClock` component,
 * which displays the elapsed time, dynamically updating the display. It also adjusts for the
 * viewer's timezone using the event's timezone offset if available.
 *
 * The elapsed time will only be shown if the event is `inProgress`, not `official`, and not `finished`
 *
 * @param {EventTableProps} props - The component's props.
 * @param {string} props.eventNum - The unique identifier of the event to display.
 * @returns {JSX.Element} A React element displaying the elapsed clock for the specified event.
 *                        If the event is not found or not in progress, an empty fragment is returned.
 *
 * @example
 * // Example usage in a parent component:
 * <EventClock eventNum="123" />
 * // Renders an elapsed clock for event "123", showing the time elapsed since the event started.
 */
const EventClock: FC<EventTableProps> = ({ eventNum }) => {
  const [event] = useEventResult(eventNum);
  if (event === undefined) {
    return <></>;
  }

  const viewerTZOffsetHrs = -new Date().getTimezoneOffset() / 60;
  let viewerTZOffsetMilli = 0;

  if (typeof event.TZOffset === 'number') {
    viewerTZOffsetMilli = (event.TZOffset - viewerTZOffsetHrs) * 60 * 60 * 1000;
  }
  let { entries } = event;
  if (!entries) entries = [];
  const finished = event[Names.N_FINISHED];
  const official = event[Names.N_OFFICIAL];

  let start = '';
  let inProgress = false;
  let finish = '';
  // console.log(JSON.stringify(entries))
  for (const row of entries) {
    const startTimeString = row[Names.N_START_TIME];
    const finishTime = row.G_Finish_time_raw;
    start = start || startTimeString;
    const adj = row[Names.N_ADJ_TIME] || '';
    const skip = adj === 'DNS' || adj === 'DNF';
    if (skip) {
      continue;
    }
    finish = finish || finishTime;
    inProgress = inProgress || Boolean(!finishTime);
    // finish = finish || adj;
  }
  inProgress = inProgress && Boolean(start) && !official && !finished;

  return <ElapsedClock viewerTZOffsetMilli={viewerTZOffsetMilli} start={start} inProgress={inProgress} finish={finish} />;
};

export default EventClock;
