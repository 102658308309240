import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useState, FunctionComponent } from "react";
import ReactRouterPrompt from "react-router-prompt";
import KeyMap from "../shared/KeyMap";
import {
  N_RESULT_OMIT_COLS,
  N_STATION_TYPE_TIMING,
  N_STATION_TYPE_PENALTIES,
  N_STATION_TYPE_TIMING_OR_PENALTIES,
  N_HANDICAP_CANADA_GARRETT,
  N_HANDICAP_US,
  N_HANDICAP_CANADA,
  N_HANDICAP_MANUAL,
  N_HANDICAP_NONE,
  N_HANDICAP_NORMALIZED,
} from "../shared/Names";
import Util from "../shared/Util";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material";
import { ChangeContext, getField, useStyles } from "./FormGlobals";
import { SelectFormField } from "./SelectFormField";
import ActionDelete from "@mui/icons-material/Delete";

const TextFormField = ({
  name,
  def,
  label,
  info,
  fullWidth,
  disabled,
  context,
}: {
  name: string;
  def?: string;
  label?: string;
  info?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  context: ChangeContext;
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <TextField
        name={name}
        variant="standard"
        fullWidth={fullWidth}
        disabled={disabled}
        label={label || name}
        value={getField(context, name, def || "")}
        onChange={context.onChange}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        error={Boolean(context.errors[name] && true)}
      />
      <FormHelperText className={classes.formHelperText}>
        {info || ""}
      </FormHelperText>
    </React.Fragment>
  );
};
const DateFormField = ({
  name,
  label,
  info,
  context,
}: {
  name: string;
  label?: string;
  info?: string;
  context: ChangeContext;
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <TextField
        name={name}
        variant="standard"
        label={label || name}
        type="date"
        value={getField(context, name)}
        onChange={context.onChange}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        error={Boolean(context.errors[name] && true)}
      />
      <FormHelperText className={classes.formHelperText}>
        {info || ""}
      </FormHelperText>
    </React.Fragment>
  );
};
const CheckFormField = ({
  name,
  label,
  info,
  context,
  disabled,
}: {
  name: string;
  label?: string;
  info?: string;
  context: ChangeContext;
  disabled?: boolean;
}) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl} margin="dense">
      <FormControlLabel
        className={classes.textlabel}
        control={
          <Checkbox
            className={classes.checkbox}
            name={name}
            checked={
              context.properties[name] === true ||
              context.properties[name] === "true"
            }
            onChange={context.onCheckChange}
            color="primary"
            disabled={disabled}
          />
        }
        label={label || name}
      />
      <FormHelperText className={classes.formHelperText}>
        {info || ""}
      </FormHelperText>
    </FormControl>
  );
};

const MultiCheckFormField = ({
  title,
  name,
  labels,
  context,
}: {
  title: string;
  name: string;
  labels?: string[];
  info?: string;
  context: ChangeContext;
}) => {
  const classes = useStyles();
  const values = context.properties[name] || [];

  return (
    <div>
      <InputLabel className={classes.formFieldTitle}>{title}</InputLabel>
      {(labels || []).map((col) => {
        return (
          <FormControl key={col} className={classes.formControl} margin="dense">
            <FormControlLabel
              className={classes.textlabel}
              control={
                <Checkbox
                  className={classes.checkbox}
                  name={col}
                  checked={values.includes(col)}
                  onChange={context.onMultiCheckChange(name, labels)}
                  color="primary"
                />
              }
              label={col}
            />
          </FormControl>
        );
      })}
    </div>
  );
};

interface RegattaConfigurationFormProps {
  onSubmit: (properties: KeyMap) => void;
  onCancel: () => void;
  onTestUrl: (properties: KeyMap) => void;
  testingUrl: boolean;
  testingStatus: string;
  properties: KeyMap;
}

interface HeaderProps {}
const Header: FunctionComponent<React.PropsWithChildren<HeaderProps>> = ({
  children,
}) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell colSpan={2}>
        <Toolbar className={classes.toolbarOption}>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            {children}
          </Typography>
        </Toolbar>
      </TableCell>
    </TableRow>
  );
};
const RegattaConfigurationForm = (props: RegattaConfigurationFormProps) => {
  const classes = useStyles();
  const [isModified, setIsModified] = useState(false);
  const [errors, setErrors] = useState<KeyMap<string>>({});
  const [properties, setProperties] = useState<KeyMap>({});

  useEffect(() => setProperties(props.properties), [props.properties]);

  const stationList = properties.RMConfig?.Stations || [];
  // Remove Penalties from cloud list since we add it explicitly
  const stationNames = [
    ...stationList.map((station) => station.stationName),
  ].filter((name) => name !== "Penalties");
  // Preconfigured stations plus cloud provided list
  const stations = [
    N_STATION_TYPE_TIMING,
    N_STATION_TYPE_PENALTIES,
    N_STATION_TYPE_TIMING_OR_PENALTIES,
    ...stationNames,
  ];
  const stationSelects = stations.map((station) => [station, station]);
  (properties.TrackingStations || []).forEach((trackingStation) =>
    stationSelects.push([trackingStation.name, trackingStation.name])
  );

  const changeContext = useMemo(() => {
    const updateProperty = (name: string, value: string) => {
      if (!name.includes("/")) {
        setProperties((prev) => ({ ...prev, [name]: value }));
        return;
      }
      if (name === "json") {
        value = value.replaceAll("\\n", "");
      }

      let parent = properties;
      const keys = name.split("/");
      keys.forEach((skey, i) => {
        const key = skey.match(/^[0-9]/) ? Number(skey) : skey;
        if (i === keys.length - 1) {
          parent[key] = value;
          return;
        }

        if (!parent[key]) {
          // Add according to child key type
          if (keys[i + 1].match(/^[0-9]/)) {
            parent[key] = [];
          } else {
            parent[key] = {};
          }
        }
        // Move down the tree
        parent = parent[key];
      });
      setProperties({ ...properties });
    };
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsModified(true);
      updateProperty(event.target.name, event.target.value);
    };
    const onSelectChange = (
      event: React.ChangeEvent<{ name?: string | undefined; value: string }>
    ) => {
      if (!event.target.name) {
        return;
      }
      if (event.target.name === "HandicapType") {
        updateProperty(
          N_HANDICAP_NORMALIZED,
          `${event.target.value === N_HANDICAP_CANADA}`
        );
      }
      setIsModified(true);
      updateProperty(event.target.name, event.target.value);
    };
    const onCheckChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      value: boolean
    ) => {
      setIsModified(true);
      setProperties({ ...properties, [event.target.name]: value });
    };

    const onMultiCheckChange =
      (propName: string, allowList: string[]) =>
      (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
        setIsModified(true);
        const current = properties[propName] || [];
        const name = event.target.name;
        if (current.includes(name)) {
          current.splice(current.indexOf(name), 1);
        }
        if (value) {
          current.push(event.target.name);
        }
        // maintain order
        const finalValue: string[] = [];
        allowList.forEach((v) => {
          if (current.includes(v)) {
            finalValue.push(v);
          }
        });
        setProperties({ ...properties, [propName]: finalValue });
      };

    return {
      properties,
      errors,
      onChange,
      onCheckChange,
      onMultiCheckChange,
      onSelectChange,
    } as ChangeContext;
  }, [properties, errors]);

  const onTrackingStationDelete = (index: number) => () => {
    properties.TrackingStations.splice(index, 1);
    setIsModified(true);
    setProperties({ ...properties });
  };

  const setError = (key: string, msg: string) => {
    setErrors({ ...errors, [key]: msg });
  };

  const onSubmit = (/* event: React.MouseEvent */) => {
    let count = 0;
    if ("" === properties.Title) {
      setError("Title", "Title must be specified");
      count++;
    }
    if ("" === properties.MobileKey || properties.MobileKey.length < 5) {
      setError("MobileKey", "Mobile Pin must be at least 5 characters long");
      count++;
    }
    if (properties.DataSource === "RegattaMaster") {
      if (
        !properties.RMRSID ||
        !String(properties.RMRSID).match(/^[0-9][0-9][0-9][0-9]+/)
      ) {
        setError("RMRSID", "RMRSID Must be a number with at least 4 digits");
        count++;
      }
    }
    if (properties.Waypoints) {
      const space = properties.Waypoints.split(",")
        .map((waypoint) => waypoint.trim())
        .join(",")
        .indexOf(" ");
      if (space >= 0) {
        setError("Waypoints", "Waypoint names should use _ instead of space");
        count++;
      }
    }
    if (properties.ResultWaypoints) {
      const space = properties.ResultWaypoints.split("=")
        .map((part) =>
          part
            .trim()
            .split(",")
            .map((waypoint) => waypoint.trim())
            .join(",")
        )
        .join("=")
        .indexOf(" ");
      if (space >= 0) {
        setError(
          "ResultWaypoints",
          "Result Waypoint names should use _ instead of space"
        );
        count++;
      }
    }
    if (properties.json) {
      try {
        JSON.parse(properties.json);
      } catch (e) {
        setError("json", "JSON is not valid");
        count++;
      }
    }
    const days = Number(properties.NumDays);
    if (days <= 0 || days > 21) {
      setError("NumDays", "Number of Days is not in a valid range");
      count++;
    }

    if (count === 0) {
      // No errors detected
      setIsModified(false);
      // Delay for isModified to propagate
      setTimeout(() => {
        props.onSubmit(properties);
      }, 100);
    }
  };

  const onCancelConfiguration = (/* event: React.MouseEvent */) => {
    setIsModified(false);
    setTimeout(() => {
      props.onCancel();
    }, 100);
  };

  const onTestUrl = (/* event: React.MouseEvent */) => {
    props.onTestUrl(properties);
  };

  const onOpenUrl = (/* event: React.MouseEvent */) => {
    window.open(properties.DocUrl, "_blank");
  };

  const renderErrors = () => {
    return Object.keys(errors).map((key) => (
      <Typography variant="subtitle1" key={key} className={classes.error}>
        {errors[key]}
      </Typography>
    ));
  };

  const renderRegattaMasterConfig = () => {
    if (properties.DataSource !== "RegattaMaster") {
      return null;
    }

    return (
      <div>
        <TextFormField
          name="RMRSID"
          label="RM Regatta ID"
          info="The Regatta Master Online regatta ID. A 4-6 digit number."
          context={changeContext}
        />
        <CheckFormField
          name="RMPublishDisable"
          label="Disable Publish"
          info="Disable publishing data to RegattaMaster Online."
          context={changeContext}
        />
      </div>
    );
  };

  const renderGoogleSheetsConfig = () => {
    if (properties.DataSource === "RegattaMaster") {
      return null;
    }
    return [
      <TableRow key="0">
        <TableCell colSpan={2}>
          <TextFormField
            name="DocUrl"
            fullWidth={true}
            label="Google Doc URL"
            context={changeContext}
          />
        </TableCell>
      </TableRow>,
      <TableRow key="1">
        <TableCell>
          {props.testingUrl ? (
            <CircularProgress size={40} className={classes.testing} />
          ) : (
            <div>
              <Button variant="contained" className="" onClick={onTestUrl}>
                Test URL
              </Button>
              <Button
                variant="contained"
                className=""
                onClick={onOpenUrl}
                style={{ marginLeft: 8 }}
              >
                Open URL
              </Button>
            </div>
          )}
        </TableCell>
        <TableCell>
          <div
            className={
              "OK" === props.testingStatus ? classes.testPass : classes.testFail
            }
          >
            {props.testingStatus}
          </div>
        </TableCell>
      </TableRow>,
    ];
  };

  const allowPublic =
    properties.DataSource === "RegattaMaster" ||
    properties.DocUrl !==
      "https://docs.google.com/spreadsheets/d/1K2UWYS9Vfb4HlHtMGRi5pGRWj4CM9ZrKNbWo58vdvh4/edit?usp=sharing";
  const resultDigits =
    properties.ResultDigits === undefined ? 3 : properties.ResultDigits;

  const rmbeta =
    (properties.MobileKey || "").indexOf("rm") === 0 ||
    properties.DataSource === "RegattaMaster";

  return (
    <div className="">
      <ReactRouterPrompt when={isModified}>
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            // <div>
            //   <p>You have unsaved changes on this page. Do you want to leave without saving?</p>
            //   <button onClick={onCancel}>Cancel</button>
            //   <button onClick={onConfirm}>OK</button>
            // </div>
            <Dialog
              open={isActive}
              // onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Unsaved Changes!"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  You have unsaved changes on this page. Do you want to leave
                  without saving?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel}>Back</Button>
                <Button onClick={onConfirm} autoFocus>
                  Discard Changes
                </Button>
              </DialogActions>
            </Dialog>
          )
        }
      </ReactRouterPrompt>

      <Paper>
        <div style={{ marginTop: "20px" }}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              Race Configuration
            </Typography>
            <div>
              <Button
                variant="contained"
                disabled={!isModified}
                onClick={onSubmit}
                style={{ marginRight: "20px" }}
              >
                Save
              </Button>
              <Button variant="contained" onClick={onCancelConfiguration}>
                Cancel
              </Button>
            </div>
          </Toolbar>
        </div>
        {renderErrors()}
        <form className={classes.container} noValidate autoComplete="off">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2}>
                  <TextFormField
                    context={changeContext}
                    name="Title"
                    info="This title is shown on www.crewtimer.com."
                    fullWidth={true}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <TextFormField
                    name="InfoText"
                    label="Race Information shown on results page"
                    fullWidth={true}
                    context={changeContext}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <DateFormField
                    context={changeContext}
                    name="Date"
                    info="The date your race will be start."
                  />
                </TableCell>
                <TableCell>
                  <TextFormField
                    context={changeContext}
                    name="NumDays"
                    def="1"
                    label="Days"
                    info="Duration in number of consecutive days"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <SelectFormField
                    name="RaceType"
                    selects={[
                      ["Head", "Head"],
                      ["Sprint", "Sprint"],
                    ]}
                    context={changeContext}
                    info="The default timing method.  Can also be specified in the spreadsheet lineups as RaceType column."
                  />
                </TableCell>
                <TableCell>
                  {properties.DataSource !== "RegattaMaster" && (
                    <SelectFormField
                      name="ResultDigits"
                      value={resultDigits}
                      selects={[
                        ["0.1", "1"],
                        ["0.01", "2"],
                        ["0.001", "3"],
                      ]}
                      context={changeContext}
                    />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <SelectFormField
                    name="FeeType"
                    label="CrewTimer Support Fee"
                    selects={[
                      ["$0.50 Per Entry Fee", "Fee"],
                      ["Free - No entry fees charged", "NoFee"],
                      ["Free - Test or evaluation", "Test"],
                      ["Free - Event Cancelled", "Cancel"],
                    ]}
                    context={changeContext}
                    info="CrewTimer is free for test and no-fee events"
                  />
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>
                  <CheckFormField
                    name="Public"
                    label="Public Visible"
                    info={
                      "Allow your race to be found on www.crewtimer.com when checked." +
                      allowPublic
                        ? "NOTE: You must customize the lineups with your own configuration to make this race public. Please do not make a test regatta public."
                        : ""
                    }
                    context={changeContext}
                  />
                </TableCell>
                <TableCell>
                  <CheckFormField
                    name="Finished"
                    info="Mark all races as official and prevent updates from the mobile app."
                    context={changeContext}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <TextFormField
                    name="MobileKey"
                    label="Mobile PIN"
                    info="A PIN used by the mobile app to authenticate connection to CrewTimer."
                    context={changeContext}
                  />
                </TableCell>

                <TableCell>
                  <CheckFormField
                    name="ResultsPendOfficial"
                    label="Hide finish times till official"
                    info="Hides the final times until a race has been marked 'Official'"
                    context={changeContext}
                  />
                  <CheckFormField
                    name="ResultsPending"
                    label="Hide all lineups and results"
                    info="Hide the results and lineups on the website"
                    context={changeContext}
                  />
                </TableCell>
              </TableRow>
              <Header>Data Source</Header>
              {rmbeta && (
                <TableRow>
                  <TableCell>
                    <SelectFormField
                      name="DataSource"
                      label="Data Source"
                      selects={[
                        ["Google Sheets", "GoogleSheets"],
                        ["Regatta Master Online", "RegattaMaster"],
                      ]}
                      context={changeContext}
                    />
                  </TableCell>
                  <TableCell>{renderRegattaMasterConfig()}</TableCell>
                </TableRow>
              )}
              {renderGoogleSheetsConfig()}

              <Header>Handicaps</Header>
              <TableRow>
                <TableCell>
                  <SelectFormField
                    name="HandicapType"
                    selects={[
                      ["None", N_HANDICAP_NONE],
                      ["Manual - Handicap column", N_HANDICAP_MANUAL],
                      ["US Rowing - Age based", N_HANDICAP_US],
                      ["RCA Henley - Age based", N_HANDICAP_CANADA],
                      [
                        "RCA Garrett-Zezza 2022 - Age based",
                        N_HANDICAP_CANADA_GARRETT,
                      ],
                    ]}
                    context={changeContext}
                  />
                </TableCell>
                <TableCell>
                  <CheckFormField
                    name="HandicapNormalized"
                    label="Normalize Handicaps"
                    info="Use zero for the lowest handicap and adjust others accordingly"
                    context={changeContext}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <TextFormField
                    name="HandicapMultiplier"
                    label="Handicap Multiplier"
                    context={changeContext}
                  />
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    Masters handicaps are based on 1K races. If your race is 6K,
                    use 6. If using Manual handicaps this should be 1 if the
                    spreadsheet already has this factor included.
                  </Typography>
                </TableCell>
              </TableRow>
              <Header>Mobile App Presets via Mobile Pin</Header>
              {[
                ...(properties.MobilePins || []),
                { pin: "", stationType: "" },
              ].map((cfg, pinNum) => (
                <TableRow key={pinNum}>
                  <TableCell>
                    <TextFormField
                      name={`MobilePins/${pinNum}/pin`}
                      label="Pin"
                      context={changeContext}
                    />
                  </TableCell>
                  <TableCell>
                    <SelectFormField
                      name={`MobilePins/${pinNum}/stationType`}
                      label="Station Type"
                      selects={stationSelects}
                      context={changeContext}
                    />
                  </TableCell>
                </TableRow>
              ))}
              <Header>Result Customization</Header>
              <TableRow>
                <TableCell colSpan={2}>
                  <MultiCheckFormField
                    title="Result columns to omit"
                    name={N_RESULT_OMIT_COLS}
                    labels={["Start", "Raw Time", "Adjust", "Stroke", "Split"]}
                    context={changeContext}
                  />
                </TableCell>
              </TableRow>
              {["Event", "Crew", "Stroke", "Bow", "Lane", "Split"].map(
                (name) => (
                  <TableRow key={name}>
                    <TableCell>{name}</TableCell>
                    <TableCell>
                      <TextFormField
                        name={`Titles/${name}`}
                        label="Title"
                        context={changeContext}
                      />
                    </TableCell>
                  </TableRow>
                )
              )}
              <Header>Waypoints</Header>
              <TableRow>
                <TableCell colSpan={2}>
                  <TextFormField
                    name="Waypoints"
                    disabled={properties.DataSource === "RegattaMaster"}
                    label="Optional Timing Waypoints.  e.g. Start2,Bridge, Pier"
                    fullWidth={true}
                    context={changeContext}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <TextFormField
                    name="ResultWaypoints"
                    label="Result Waypoints to show on website.  Leave blank to show all Timing Waypoints. Equations allowed."
                    fullWidth={true}
                    context={changeContext}
                  />
                </TableCell>
              </TableRow>
              <Header>Penalties</Header>
              <TableRow>
                <TableCell colSpan={2}>
                  <TextFormField
                    name="CustomCodes"
                    label="Custom Penalty Codes.  e.g. ABC:10,Buoy:30"
                    fullWidth={true}
                    context={changeContext}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <TextFormField
                    name="PenaltyLocations"
                    label="Optional Penalty Locations.  e.g. Bridge1,Corner2"
                    fullWidth={true}
                    context={changeContext}
                  />
                </TableCell>
              </TableRow>
              <Header>Tracking Points (e.g. Launch, Recovery)</Header>
              {[...(properties.TrackingStations || []), { name: "" }].map(
                (cfg, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={1}>
                      <TextFormField
                        name={`TrackingStations/${index}/name`}
                        label="Name"
                        context={changeContext}
                      />
                    </TableCell>
                    <TableCell>
                      {cfg.name && (
                        <IconButton
                          // className={classes.menuButton}
                          color="inherit"
                          aria-label="Menu"
                          onClick={onTrackingStationDelete(index)}
                          size="large"
                        >
                          <ActionDelete />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                )
              )}
              <Header>Admin</Header>
              <TableRow>
                <TableCell colSpan={2}>
                  <TextFormField
                    name="Admins"
                    info="Email addresses with admin access.  comma separated ."
                    context={changeContext}
                    fullWidth={true}
                  />
                </TableCell>
              </TableRow>
              {Util.isUserAdmin() && (
                <>
                  <TableRow>
                    <TableCell>
                      <TextFormField
                        name="Owner"
                        info="Email address of race 'Owner'"
                        context={changeContext}
                      />
                    </TableCell>
                  </TableRow>
                </>
              )}
              <Header>Special Features</Header>
              <TableRow>
                <TableCell colSpan={2}>
                  <TextFormField
                    name="json"
                    info="Custom json configuration for some points engines."
                    context={changeContext}
                    fullWidth={true}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </form>
      </Paper>
    </div>
  );
};

export default RegattaConfigurationForm;
